import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import Item from './components/Item.vue'
import ListItem from './components/ListItem.vue'

export default {
	data: function(){
		return {
			isReady: false,
			message: '',
			tab: 'map',
			list: [],
			dispList: [],
			dMap: {},
			city: [],
			selectedCity: 'select',
			usages: {},
			isSearchMenu: false,
		}
	},

	watch: {
		selectedCity: function(n, o){
			if(n != 'select')
				this.moveMap(n)
			this.createDispList()
		},
	},

	mounted: async function(){
		this.isReady = true
		await this.createMap()
		//const pos = await this.getPosition()
		setTimeout( () => {
			//this.dMap.panTo(pos)
			this.dMap.setZoom(13)
		}, 800)
		await this.getList()

		this.createDispList()

		this.getCity()

	},

	methods: {
		waitForGoogle: function(){
			return new Promise( (resolve, reject) => {
				let timer = setInterval( () => {
					if(window.google){
						clearInterval(timer)
						resolve()
					}
				}, 500)
			})
		},

		createMap: async function(){
			await this.waitForGoogle()
			//let pos = {lat: 38.24056, lng: 140.36333} //山形市
			let pos = {lat: 39.71861, lng: 140.1025} //秋田県
			this.dMap = new google.maps.Map(this.$refs.map, {
				center: pos,
				zoom: 10,
				streetViewControl: false,
				mapTypeControl: false,
				zoomControl: false,
				fullscreenControl: false,
			})
		},

		getPosition: function(){
			return new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition((pos)=>{
					let center = {lat: pos.coords.latitude, lng: pos.coords.longitude}
					resolve(center)
				})
			})
		},

		//物件リストを取得する
		getList: async function(){
			const res = await this.$post('/web_api/project/list')
			this.list = res.list
			//usagesを作る
			let tmp = {}
			for(let i=0, l=res.list.length; i<l; i++)
				tmp[res.list[i].usage] = {selected: true}
			this.usages = tmp

			//google mapのmarkerを作成する
			for(let i=0, l=this.list.length; i<l; i++){
				const d = this.list[i]
				let m = {
					id: i,
					position: {lat: d.lat, lng: d.lng},
					title: 'maker1',
					draggable: false,
				}
				d.marker = new google.maps.Marker(m)
				d.marker.setMap(this.dMap)
				d.marker.addListener('click', () => {
					d.is_open = true
				})
			}
		},

		//表示リストを作成する
		createDispList: function(){
			//地域と用途でフィルタリング
			let dList = []
			for(let i=0, l=this.list.length; i<l; i++){
				const d = this.list[i]
				d.marker.setVisible(false)
				//地域のフィルタリング
				if(this.selectedCity != 'select' && this.selectedCity.n != d.area)
					continue
				//用途のフィルタリング
				if(!this.usages[d.usage].selected)
					continue
				dList.push(d)
				d.marker.setVisible(true)
			}
			this.dispList = dList
		},

		getCity: function(){
			fetch('/data/city.json')
				.then((response) => response.json())
				.then((data) => {
					this.city = data.city
				})
		},

		moveMap: function(city){
			const pos = {lat: city.lat, lng: city.lng}
			this.dMap.panTo(pos)
		},

		//用途を変更
		changeUsage: function(u){
			u.selected = ! u.selected
			this.createDispList()
		},

	},

	components: {
		Header,
		Footer,
		Item,
		ListItem,
	},
}
