<template>
<div class="inputImage">
	<div class="imageWrp" v-show="url != ''" @click="isPreview = true">
		<img :src="url">
	</div><!--imageWrp-->

	<div class="iiModal preview" v-show="isPreview" @click.stop="isPreview = false">
		<img :src="url">
	</div><!--preview-->

</div><!--inputImage-->
</template>

<style scoped>
.inputImage{
	position: relative;
	padding: 20px 10px;
	overflow: visible;
}
.imageWrp{
	width: 180px;
	height: 120px;
	background-color: #f0f0f0;
	position: relative;
}
.imageWrp img{
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	object-fit: cover;
}
.iiModal{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 910;
	background-color: rgba(0,0,0,0.6);
}
.iiModal img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}
</style>

<script>
export default {
	props: {
		url: {type: String, default: ''},
	},

	data: function(){
		return {
			isPreview: false,
		}
	},

	methods: {
	},

}
</script>
