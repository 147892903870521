import { createApp } from 'vue'
//import { createGtm } from '@gtm-support/vue-gtm'
import { post } from './lib/utils'
import App from './App.vue'
import router from './router'
import store from './store'

let GTMID = 'GTM-TSJSFFM'
let CDN = '/cdn'
/*const uri = new URL(window.location.href)
if(uri.hostname.indexOf('localhost') >= 0){
}else if(uri.hostname.indexOf('dev') >= 0){
	//staging
	GTMID = 'GTM-TSJSFFM'
	CDN = 'https://cdn.dev2.stockpoint.jp/spm'
}else{
	//production
	GTMID = 'GTM-KDG7L2R'
	CDN = 'https://cdn.stockpoint.jp/spm'
}*/

const app = createApp(App)
app.use(router)
app.use(store)

/*const gtm = createGtm({
	id: GTMID,
	vueRouter: router,
})
app.use(gtm)*/

app.config.globalProperties.$cdn = CDN
app.config.globalProperties.$post = post

app.mount('#app')

