<template>
<div>

<div class="modal" v-show="isOpen" @click="close">
	<div class="modalWrp" @click.stop="">
		<p class="name">{{d.title}}</p>

		<h3>地域</h3>
		<p class="area">{{d.area}}</p>

		<h3>用途</h3>
		<p class="usage">{{d.usage}}</p>

		<h3>広さ</h3>
		<p class="usage">{{d.area_size}}</p>

		<h3>価格</h3>
		<p class="usage">{{d.price}}</p>

		<h3>内容</h3>
		<pre class="memo">{{d.memo}}</pre>

		<h3>所在地</h3>
		<iframe v-show="mapUrl" :src="mapUrl" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen=""></iframe>

		<h3 v-show="d.pics.length > 0">写真</h3>
		<div class="picsWrp">
			<ul class="pics" :style="{width: (d.pics.length * 200) + 'px'}">
				<li v-for="(l, i) in d.pics" key="i">
					<DispImage :url="l" />
				</li>
			</ul>
		</div><!--picsWrp-->

		<div class="btns">
			<a class="btn" @click="$router.push({name: 'Ask', params:{id: d.id}})">お問い合わせする</a>
		</div><!--btns-->

<a class="close" @click="close"><img src="/img/top/btn_close.png"></a>
	</div><!--modalWrp-->
</div><!--modal-->

</div>

</template>

<style scoped>
.modal{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 10;
}
.modalWrp{
	position: absolute;
	background-color: white;
	width: 100%;
	max-width: var(--max-width);
	height: 100%;
	left: 0;
	right: 0;
	margin: auto;
	overflow-y: auto;
	padding-bottom: 120px;
}
.modalWrp h3{
	margin-top: 30px;
	font-size: 14px;
}
.modalWrp>p{
	width: calc(100% - 40px);
	margin: 0 auto 10px auto;
}
.modalWrp .name{
	background-color: var(--col-main);
	color: white;
	padding: 10px 20px;
	font-weight: bold;
	width: 100%;
	margin: 0;
}

.modalWrp .memo{
	width: calc(100% - 40px);
	margin: 0 auto 10px auto;
	position: relative;
}
.modalWrp .picsWrp{
	width: 100%;
	max-width: var(--max-width);
	overflow: auto;
}
.modalWrp .pics{
	text-align: center;
}
.modalWrp .pics li{
	display: inline-block;
	width: 195px;
	margin-right: 5px;
}
.modalWrp .btns{
	position: fixed;
	bottom: 20px;
	height: 80px;
	left: 0;
	right: 0;
	width: 100%;
}
.modalWrp .btns .btn{
	margin: auto;
}
.close{
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
</style>

<script>
import DispImage from '../../../components/DispImage.vue'

export default {
props: {
	d: {type: Object, default: () => {return {} }},
	i: {type: Number, default: -1},
	isOpen: {type: Boolean, default: false},
	close: {type: Function, default: () => { return {} }},
},

data: function(){
	return {
		mapUrl: '',
	}
},

mounted: async function(){
	this.mapUrl = 'https://maps.google.com/maps?output=embed&q=' + this.d.lat + ',' + this.d.lng + '&t=m&hl=ja&z=14'
},

methods: {
},

components: {
	DispImage,
},

}
</script>

