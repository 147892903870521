import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index/Index.vue'
import Home from '../views/Home/Home.vue'
import Ask from '../views/Ask/Ask.vue'
import AskDone from '../views/AskDone/AskDone.vue'
import AboutUs from '../views/AboutUs/AboutUs.vue'

const routerHistory = createWebHashHistory()

const routes = [
	//トップページ
  {
    path: '/',
    name: 'Root',
    component: Home,
  },
	//ホーム
  {
		path: '/home',
    name: 'Home',
    component: Home,
  },
	//お問い合わせ
  {
		path: '/ask/:id',
    name: 'Ask',
    component: Ask,
  },
  {
		path: '/askdone',
    name: 'AskDone',
    component: AskDone,
  },
  {
		path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs,
  },
]

const scrollBehavior = (to, from, savedPosition) => {
	if(savedPosition){
		return savedPosition;
	}else{
		return { top: 0 }
	}
}

const router = createRouter({
  history: routerHistory,
  routes,
	scrollBehavior,
})

router.afterEach((to, from) => {
	document.title = to.meta.title || '農地マッチング'
})

export default router
