import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'

export default {
	data: function(){
		return {
			checkList: [],
			searchText: '',
			dMap: {},
		}
	},

	mounted: async function(){
		fetch('/data/list.json', {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json() )
			.then((data) => this.checkList= data.list)

		await this.createMap()
		let pos = await this.getPosition()
		setTimeout( () => {
			this.dMap.panTo(pos)
			this.dMap.setZoom(9)
		}, 800)

		for(let i=0, l=this.checkList.length; i<l; i++){
			const item = this.checkList[i]
			let m = {
				id: i,
				position: {lat: item.lat, lng: item.lng},
				title: 'maker1',
				draggable: false,
			}
			this.createMarker(m)
		}

	},

	methods: {
		search: async function(){
			this.checkList[1].is_open = true
		},

		waitForGoogle: function(){
			return new Promise( (resolve, reject) => {
				let timer = setInterval( () => {
					if(window.google){
						clearInterval(timer)
						resolve()
					}
				}, 500)
			})
		},

		createMap: async function(){
			await this.waitForGoogle()
			let pos = {lat: 38, lng: 140}
			this.dMap = new google.maps.Map(this.$refs.map, {
				center: pos,
				zoom: 6,
				streetViewControl: false,
				mapTypeControl: false,
				zoomControl: false,
				fullscreenControl: false,
			})
		},

		getPosition: function(){
			return new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition((pos)=>{
					let center = {lat: pos.coords.latitude, lng: pos.coords.longitude}
					resolve(center)
				})
			})
		},

		createMarker: function(marker){
			let m = new google.maps.Marker(marker)
			m.setMap(this.dMap)
			m.addListener('click', () => {
				this.checkList[marker.id].is_open = true
			})
		},

		modalClose: function(i){
			this.checkList[i].is_open = false
		},

	},

	components: {
		Header,
		Footer,
	},
}
