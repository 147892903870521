<template>
<div class="wrp">
	<div class="contents">
		<h1 @click="toHome">未耕作地　検索</h1>
	</div>
</div>
</template>

<style scoped>
.wrp{
	width: 100%;
	height: 80px;
	overflow: hidden;
/*	border-bottom: 1px solid var(--col-main);*/
	background-color: var(--col-main);
}
.contents{
	width: calc(100% - 40px);
	max-width: var(--max-width);
	margin: 0 auto;
	position: relative;
}
h1{
	font-size: 28px;
	font-weight: 700;
	color: white;
	line-height: 80px;
	text-align: left;
	width: auto;
	padding: 0;
	max-width: none;
	cursor: pointer;
}
p{
	position: absolute;
	top: 20px;
	right: 0;
}
</style>

<script>
export default {
methods: {
	toHome: function(){
		if(this.$route.name == 'Home') return false
		this.$router.push({name: 'Home'})
	},
},
}
</script>
