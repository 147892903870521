<div v-show="isReady">
<Header />

<div class="main">

	<pre class="notes">
本サイトは未耕作地を検索するサービスです。
	</pre>

	<p class="message">{{message}}</p>

	<div class="contentsWrp">
		<div class="search">
			<h4>地域で検索</h4>
			<select v-model="selectedCity">
				<option value="select">全地域</option>
				<option v-for="(c, i) in city" :value="c">{{c.n}}</option>
			</select>

			<br>

			<h4>用途で絞り込み</h4>
			<ul class="usages">
				<li v-for="(u, n) in usages" @click="changeUsage(u)" :class="{active: u.selected}">{{n}}</li>
			</ul>
		</div><!--search-->


		<div class="search smt" v-show="isSearchMenu">
			<h4>地域で検索</h4>
			<select v-model="selectedCity">
				<option value="select">全地域</option>
				<option v-for="(c, i) in city" :value="c">{{c.n}}</option>
			</select>

			<br>

			<h4>用途で絞り込み</h4>
			<ul class="usages">
				<li v-for="(u, n) in usages" @click="changeUsage(u)" :class="{active: u.selected}">{{n}}</li>
			</ul>
			<img class="closeBtn" src="/img/top/btn_close_green.png" @click="isSearchMenu = false">

			<a class="btn sub small" @click="isSearchMenu = false">OK</a>
		</div><!--search-->



		<div class="right">
			<div class="tabs">
				<a @click="tab = 'map'" :class="{active: tab == 'map'}">地図</a>
				<a @click="tab = 'list'" :class="{active: tab == 'list'}">リスト</a>

				<img src="/img/top/btn_menu.png" class="searchMenu" @click="isSearchMenu = true">
			</div><!--tabs-->

			<div class="result">
				<div class="map" v-show="tab == 'map'">
					<div ref="map" id="map"></div>
				</div><!--map-->

				<ul class="list" v-show="tab == 'list'">
					<li v-for="(l, i) in dispList" :key="i">
						<ListItem :l="l" :showDetail="() => {l.is_open = true}"/>
					</li>
				</ul><!--list-->
			</div><!--result-->
		</div><!--right-->

	</div><!--contentsWrp-->

	<div v-for="(l, i) in list" :key="i">
		<Item :d="l" :i="i" :isOpen="l.is_open" :close="() => {l.is_open = false}" />
	</div>

</div><!--contents-->

<Footer />

</div>
