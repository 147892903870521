<div>
	<Header />

<div class="main">

	<pre class="notes">
お問い合わせいただき、ありがとうございました。
内容を確認の上、ご登録いただきましたメールアドレス宛てに返信させていただきます。
	</pre>

	<a class="btn sub" @click="$router.push({name: 'Home'})">ホームに戻る</a>

</div><!--main-->
</div>
