import {check_email} from '../../lib/utils.js'
import Header from '../../components/Header.vue'

export default {
	data: function(){
		return {
			message: '',
			isModal: false,
			name: '',
			email: '',
			memo: "",
			errName: false,
			errEmail: false,
			errMemo: false,
		}
	},

	mounted: async function(){
	},

	methods: {
		cnfm: function(){
			if(this.check()){
				this.isModal = true
				return true
			}
		},

		sbmt: async function(){
			if(! this.check()){
				return false
			}
			let d = {
				id:    this.$route.params.id,
				email: this.email,
				name:  this.name,
				memo:  this.memo,
			}
			const res = await this.$post('/web_api/contact/create', d)
			if(res.code != 0){
				this.message = res.message
				this.isModal = false
				window.scrollTo({top: 0, behavior: 'smooth'})
				return false
			}
			this.$router.push({name: 'AskDone'})
		},

		check: function(){
			this.errName = false
			this.errEmail = false
			this.errMemo = false
			if(this.name == '') this.errName = true
			if(! check_email(this.email)) this.errEmail = true
			if(this.memo == '') this.errMemo = true
			if(this.errName || this.errEmail || this.errMemo) return false
			return true
		},
	},

	components: {
		Header,
	},
}
