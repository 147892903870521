import axios from 'axios'

export async function post(url, data, addHeaders={}, method='post'){
	let headers = {
		'Content-Type'     : 'application/json',
		'pragma'           : 'no-cache',
		'Cache-Control'    : 'no-cache',
		'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT',
	}

	//jwtを設定
	let jwt = localStorage.getItem('jwt')
	headers['SFT-Auth'] = 'Bearer ' + jwt

	//ヘッダ追加分
	for(let key in addHeaders){
		headers[key] = addHeaders[key]
	}
	
	return await axios({
		method: method,
		url:  url + '.json',
		data: data,
		headers: headers,
		responseType: 'json',
	})
	.then(function(res){
		console.log(res)
		return res.data
	})
	.catch(function(e){
		console.warn(e)
		if(e.response.data && e.response.data.code){
			return e.response.data
		}else{
			return {code: 500, message: 'ネットワークの接続に失敗しました。再度実施してください。'}
		}
	})
}

/*メールアドレス形式チェック*/
export function check_email(str){
	const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/
	if(pattern.test(str)) return true
	return false
}

