import Header from '../../components/Header.vue'

export default {
	data: function(){
		return {
			isModal: false,
			name: '',
			email: '',
			memo: "",
		}
	},

	mounted: async function(){
	},

	methods: {
	},

	components: {
		Header,
	},
}
