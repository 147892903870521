<div>
	<Header />

<div class="contents">
	<h1>会社概要</h1>

	<h3>会社名</h3>
	<p>On My Ways株式会社</p>

	<h3>設立</h3>
	<p>2022年12月x日</p>

	<h3>代表者</h3>
	<p>xxxx</p>

	<h3>事業概要</h3>
	<pre>農地マッチングサービス
ああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
ああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
</pre>

</div><!--contents-->

	<Footer />

</div>
