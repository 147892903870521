<div>
	<Header />

<div class="main">

	<p class="message">{{message}}</p>

	<pre class="notes">
必要事項をご記入ください。
	</pre>

	<h4>お問い合わせ物件番号</h4>
	<p class="id">{{$route.params.id}}</p>

	<br>

	<h4>お名前</h4>
	<input type="text" v-model="name" placeholder="お名前">
	<p class="errMsg" v-show="errName">お名前をご入力ください</p>

	<br>

	<h4>連絡可能なメールアドレス</h4>
	<input type="email" v-model="email" placeholder="メールアドレス">
	<p class="errMsg" v-show="errEmail">メールアドレスを正しくご入力ください</p>

	<br>

	<h4>お問い合わせの内容</h4>
	<textarea v-model="memo" placeholder="お問い合わせ内容をご記入ください"></textarea>
	<p class="errMsg" v-show="errMemo">お問い合わせ内容をご入力ください</p>

	<div class="btns">
		<a class="btn confirm" @click="cnfm">内容を確認する</a>
		<a class="btn cancel" @click="$router.push({name: 'Home'})">キャンセル</a>
	</div><!--btns-->
	


	<div class="modal conf" v-show="isModal">
		<div class="wrp">
			<div class="mhead">内容のご確認</div>
			<pre class="notes">お問い合わせ内容をご確認ください。</pre>

			<h4>お名前</h4>
			<p class="name">{{name}}</p>

			<br>

			<h4>連絡可能なメールアドレス</h4>
			<p class="email">{{email}}</p>

			<br>

			<h4>お問い合わせの内容</h4>
			<pre class="memo">{{memo}}</pre>

			<a class="btn done" @click="sbmt">この内容で送信する</a>
			<a class="btn cancel" @click="isModal = false">内容を修正する</a>

		</div><!--wrp-->
	</div><!--modal conf-->


</div><!--main-->
</div>
