<div>
	<div class="header">
		<h1>トップ</h1>
	</div><!--header-->

	<div class="btns">
		<a class="btn" @click="$router.push({name: 'CreateAccount'})">会員登録</a>
		<a class="btn" @click="$router.push({name: 'CreateAccount'})">ログイン</a>
	</div><!--btns-->

</div>
