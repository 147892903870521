<template>
<div class="listItemWrp" @click="showDetail(l)">
	<p class="title">{{l.title}}</p>
	<pre class="memo">{{l.memo}}</pre>
	<div class="img" v-show="l.pics.length > 0">
		<img :src="l.pics[0]">
	</div><!--img-->
</div><!--listItemWrp-->
</template>

<style scoped>
.listItemWrp{
	position: relative;
	min-height: 120px;
	border-bottom: 1px solid #eee;
	padding: 20px;
	background: linear-gradient(to Bottom, var(--col-bg), white, white, white);
	cursor: pointer;
}
.title{
	font-weight: bold;
	margin-right: 130px;
}
.memo{
	margin-right: 130px;
	font-size: 14px;
	line-height: 20px;
	max-height: 60px;
	overflow: hidden;
}
.img{
	display: block;
	position: absolute;
	width: 120px;
	height: 80px;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto;
	background-color: #eee;
	border-radius: 6px;
	border: 1px solid var(--col-bg);
	overflow: hidden;
}
.img>img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
</style>

<script>
export default {
props: {
	l: {type: Object, default: () => { return {} } },
	showDetail: {type: Function, default: (l) => {} },
},
}
</script>
