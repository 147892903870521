<template>
<div class="wrp">
	<div class="contents">

		<!--a :class="{active: $route.name == 'Home'}" @click="linkTo('Home')">トップ</a-->
		<!--a :class="{active: $route.name == 'AboutUs'}" @click="linkTo('AboutUs')">会社概要</a-->
		<!--a :class="{active: $route.name == 'AskUs'}" @click="linkTo('AskUs')">お問い合わせ</a-->

	</div><!--contents-->

	<!--div class="copy">&copy; 2022 OnMyWays Inc.</div-->

</div><!--wrp-->
</template>

<style scoped>
.wrp{
	width: 100%;
	overflow: hidden;
	background-color: var(--col-bg);
	margin-top: 60px;
	padding-top: 40px;
}
.contents{
	width: calc(100% - 40px);
	max-width: 1024px;
	margin: 0 auto;
	position: relative;
	text-align: center;
}
a{
	display: inline-block;
	width: 120px;
	text-align: center;
	cursor: pointer;
}
a.active{
	font-weight: bold;
	color: #666;
	cursor: normal;
}
.copy{
	margin: 20px auto;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	opacity: 0.8;
}
</style>

<script>
export default {
methods: {
	linkTo: function(name){
		if(this.$route.name == name) return false
		this.$router.push({name: name})
	},
},
}
</script>
